export const Card = {
  baseStyle: {
    color: "white",
    border: "1px solid",
    borderColor: "gray.700",
    bg: "blue.900",
    borderRadius: "12px",
    padding: "32px",
  },
}
